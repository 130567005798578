/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
// import './FooterMenu.scss';
import {
  Collapse,
  LinearProgress,
  ButtonBase,
  Drawer,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import MdaAPI from '../../../../api/MdaApi';
import { createOrderRef, formatMoney } from '../../../../helpers/tools';
import MethodCheckOut from '../../../CheckOut/_components/constructor/components/MethodCheckOut';
import BankCheckOut from '../../../CheckOut/_components/constructor/components/BankCheckOut';
import SellAPI from '../../../../api/SellAPI';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}


const FooterMenu = ({
  order, products, files, addFile,
}) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [payIndex, setPayIndex] = React.useState(0);
  const [payment, setPayment] = React.useState(null);
  const [bank, setBank] = React.useState(null);
  const authUser = useSelector(state => state.authUser);
  const customer = useSelector(state => state.customer);
  const [openDeliverConfirm, setOpenDeliverConfirm] = React.useState(false);
  const {
    user: {
      config: {
        store, id, pos_no_cobrar, pos_no_entregar,
      },
    },
  } = authUser;
  const mdaApi = new MdaAPI();

  const calculateBalance = (products) => {
    let balance = 0;
    products.map((prod) => {
      balance += parseFloat(prod.precio_neto) * parseFloat(prod.cantidad);
    });

    return balance;
  };

  const deliverOrder = async () => {
    if (!products.length) {
      toast.warn('Debe seleccionar al menos un producto antes de entregar');

      return;
    }
    const ref = `SC-${createOrderRef()}-${id || '-1'}`;
    const date = new Date();

    setLoading(true);
    const response = await mdaApi.deliver2(order, products, ref, store.crmid, date);

    if (response.message.success) {
      toast.success(response.message.success);
    }
    if (response.message.error) {
      toast.error(response.message.error);
    }
    setLoading(false);
    history.push('/entregas');
  };

  const selectPaymentMethod = (pay) => {
    setPayment(pay);
    try {
      if (pay.body && pay.body.length === 1) {
        setBank(pay.body[0]);
      } else {
        setBank(null);
      }
    } catch (error) {
      toast.error('Error seleccionando el banco o caja');
    }
  };

  const selectBank = async (banc) => {
    await setBank(null);
    await setBank(banc);
  };


  const handleSetPayIndex = (index) => {
    setPayIndex(index);
  };

  const cobrar = async () => {
    const sellApi = new SellAPI();
    const newPayment = {
      monto: calculateBalance(products),
      // @ts-ignore
      metodo: payment.method,
      // @ts-ignore
      tcybid: bank?.crmid,
      referencia: bank?.referencia,
    };
    const response = await sellApi._fde('I', [newPayment], order.relatedtoid);
    const { success } = response;
    if (success) {
      toast.success('Cobro realizado correctamente');
      deliverOrder();
    } else {
      toast.error('Ha ocurrido un error realizando el cobro');
    }
    setIsOpen(false);
  };

  const collect = () => {
    localStorage.setItem('recibirOrdenId', order.crmid);
    history.push(`/almacenes/recibir/transferencia/${order.transferencia_por_recibir}/true`);
  };


  return (
    <div className="detailFooter">
      {loading && (
      <LinearProgress className="w-100" />
      )}
      <Collapse
        in={!loading}
        className="w-100"
        timeout={{
          appear: 0,
          enter: 250,
          exit: 100,
        }}
      >
        <ButtonBase
          className="w-100 p-1 "
          style={{ backgroundColor: '#F6F6F7', outline: 'none' }}
          onClick={() => setOpen(!open)}
        >
          <KeyboardArrowUp
            className={open ? 'mda_drop' : 'mda_drop noDropDown'}
          />
        </ButtonBase>
      </Collapse>
      <Collapse
        in={open}
        className="w-100"
        timeout={{
          appear: 100,
          enter: 250,
          exit: 300,
        }}
      >
        <div className="d-flex">
          <div style={{
            minHeight: '50%', padding: '10px', flexGrow: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end',
          }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <span style={{ fontSize: '14px', fontWeight: 500 }}>Balance:</span>
              <span style={{ fontSize: '15px', fontWeight: 500 }}>
                $
                {formatMoney(calculateBalance(products))}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <span style={{ fontSize: '14px', fontWeight: 700 }}>Total:</span>
              <span style={{ fontSize: '16px', fontWeight: 700 }}>
                $
                {formatMoney(calculateBalance(products))}
              </span>
            </div>
          </div>
        </div>
      </Collapse>
      <Collapse
        in={!loading}
        className="w-100"
        timeout={{
          appear: 0,
          enter: 250,
          exit: 100,
        }}
      >
        <div className="d-flex">
          {
            !open && (
              <div style={{
                minHeight: '50%', padding: '10px', flexGrow: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end',
              }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: '14px', fontWeight: 500 }}>Balance:</span>
                  <span style={{ fontSize: '15px', fontWeight: 500 }}>
                    $
                    {formatMoney(calculateBalance(products))}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: '14px', fontWeight: 700 }}>Total:</span>
                  <span style={{ fontSize: '16px', fontWeight: 700 }}>
                    $
                    {formatMoney(calculateBalance(products))}
                  </span>
                </div>
              </div>
            )
          }
          {
            order.tse_estado_i !== '7- Entregado parcial' && (
              <div style={{ flexGrow: 1, display: 'flex' }}>
                {
                  ['3- Preparando recoleccion', '4- Pendiente de recoleccion', '4- Recoleccion Pendiente'].includes(`${order.tse_estado_i}`) && order.transferencia_por_recibir && (
                  <ButtonBase
                    className="detailFooter_btn"
                    style={{ backgroundColor: '#F7C379' }}
                    onClick={() => collect()}
                  >
                    <FontAwesomeIcon size="2x" icon="hand-holding" />
                    <span>Recolectar</span>
                  </ButtonBase>
                  )
                }
                {/* <ButtonBase
                  className="detailFooter_btn"
                  style={{ backgroundColor: '#F05B70' }}
                  onClick={() => {}}
                >
                  <FontAwesomeIcon size="2x" icon="cart-arrow-down" />
                  <span>Devuelto</span>
                </ButtonBase> */}

                {
                  ['crédito', 'credito', 'pagado'].includes(`${order.tse_tipo_pago}`.toLowerCase())
                  && !['3- Preparando recoleccion', '4- Pendiente de recoleccion', '4- Recoleccion Pendiente', '6- Entregado'].includes(`${order.tse_estado_i}`) && (
                  <ButtonBase
                    className="detailFooter_btn"
                    style={{ backgroundColor: '#23A1DB' }}
                    onClick={() => {
                      if (order.tse_tipo_pago === 'Credito' && !files.result.length) {
                        addFile();
                        toast.warn('Debe adjuntar el documento de entrega');
                      } else {
                        // deliverOrder2();
                        setOpenDeliverConfirm(true);
                      }
                    }}
                    disabled={pos_no_entregar === '1' || loading}
                  >
                    <FontAwesomeIcon size="2x" icon="shopping-cart" />
                    <span>{order.tse_tipo_pago === 'Credito' && !files.result.length ? 'Entregar con Documento' : 'Entregar'}</span>
                  </ButtonBase>
                  )
                }
                {
                ['crédito', 'credito', 'contra entrega'].includes(`${order.tse_tipo_pago}`.toLowerCase())
                && !['3- Preparando recoleccion', '4- Pendiente de recoleccion', '4- Recoleccion Pendiente'].includes(`${order.tse_estado_i}`) && (
                <ButtonBase
                  className="detailFooter_btn"
                  style={{ backgroundColor: '#8051A1' }}
                  onClick={() => setIsOpen(true)}
                  disabled={pos_no_cobrar === '1' || pos_no_entregar === '1'}
                >
                  <FontAwesomeIcon size="2x" icon="hand-holding-usd" />
                  <span>Cobrar y entregar</span>
                </ButtonBase>
                )
                }
              </div>
            )
          }
        </div>
      </Collapse>

      <Drawer
        anchor="top"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="checkout_drawer"
      >
        <div className="p-2">
          <h3>Cobrar y entregar</h3>
          <div>
            <MethodCheckOut payment={payment} selectPayment={pay => selectPaymentMethod(pay)} />
            {
            payment && (
              <BankCheckOut payment={payment} bank={bank} setBank={banc => selectBank(banc)} setPayIndex={handleSetPayIndex} />
            )
          }
          </div>
          {
            payment && bank && (
            <div className="py-4 px-2">
              <input
                style={{
                  height: '48px',
                  padding: '8px',
                  border: '2px solid #8252a1',
                  borderRadius: '10px',
                  width: '100%',
                  textAlign: 'right',
                  fontSize: '20px',
                  color: 'black',
                }}
                disabled
                type="text"
                value={`$ ${formatMoney(calculateBalance(products))}`}
              />
              <div className="w-100 pt-4 h-auto amount_checkout">
                <Button
                  id="checkout_submit"
                  onClick={() => cobrar()}
                  tabIndex={-1}
                  disabled={false}
                >
                  Cobrar y entregar
                </Button>
              </div>
            </div>
            )
          }
        </div>
      </Drawer>

      <Dialog
        open={openDeliverConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDeliverConfirm(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Confirmación de entrega</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Revise que la información del envío este correcta antes de realizar la entrega. ¿Desea continuar con la entrega?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeliverConfirm(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={
            () => {
              setOpenDeliverConfirm(false);
              deliverOrder();
            }
          }
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FooterMenu.propTypes = {
  order: PropTypes.object.isRequired,
  products: PropTypes.array,
  files: PropTypes.array,
  addFile: PropTypes.func,
};

FooterMenu.defaultProps = {
  products: [],
  files: { result: [] },
  addFile: () => {},
};

export default FooterMenu;
