import React from 'react';
import PropTypes from 'prop-types';

/* Material */
import { Button } from '@material-ui/core';

const AmountCheckOut = ({
  title,
  onSubmit,
  enableSubmit,
}) => (
  <div className="w-100 pt-4 h-auto amount_checkout">
    <Button
      id="checkout_submit"
      onClick={() => onSubmit()}
      tabIndex={-1}
      disabled={!enableSubmit}
    >
      {title}
    </Button>
  </div>
);
AmountCheckOut.propTypes = {
  title: PropTypes.string,
  enableSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

AmountCheckOut.defaultProps = {
  title: 'Cobrar',
  enableSubmit: false,
};

export default AmountCheckOut;
