/* eslint-disable import/no-named-as-default */
/* eslint-disable camelcase */
import { faFileInvoice, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse, IconButton, LinearProgress, List, ListItem, ListItemText,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import OrderAPI from '../../../api/OrderAPI';
import {
  formatString,
  getColorDeliverStatus,
} from '../../../helpers/tools';
import ListItemGeneric from '../../../views/ClientPage/components/ListItemGeneric/ListItemGeneric';

import CustomDatePicker from '../../CustomInputs/components/custom-date-picker';
import IconWidthBG from '../../Icons/IconWidthBG/IconWidthBG';
import ShipmentExpand from './components/ShipmentExpand';

const Shipments = () => {
  const [loading, setLoading] = React.useState(false);
  const [deliverList, setDeliverList] = React.useState([]);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const [search, setSearch] = useState('subject');
  const authUser = useSelector(state => state.authUser);
  const { user: { config: { store } } } = authUser;

  const getPending = async () => {
    setLoading(true);
    const orderApi = new OrderAPI();
    const response = await orderApi.orderDeliverRequest(store.crmid);
    const { success, result } = response;
    if (success) {
      setDeliverList(result);
      setFilteredOrders(result);
    }
    setLoading(false);
  };

  const getDirection = (tipo, item) => {
    if (tipo === 'origen') {
      return `${formatString(item?.orig_street)}, # ${formatString(item?.orig_num_ext)}, ${formatString(item?.orig_neighborhood)}, ${formatString(item?.orig_postcode)}, ${formatString(item?.orig_city)}`;
    }
    return `${formatString(item?.dest_street)}, # ${formatString(item?.dest_num_ext)}, ${formatString(item?.dest_neighborhood)}, ${item?.dest_postcode}, ${formatString(item?.dest_city)}`;
  };


  const handleChangeDate = (name, date) => {
    // debugger;
    const sales = [...deliverList];
    if (date[0] === null && date[1] === null) {
      setFilteredOrders([...sales]);

      return;
    }

    if (date[0] !== null && date[1] !== null) {
      const filter = sales.filter(item => moment(item[`${search}`]).isBetween(
        moment(date[0]).startOf('days'),
        moment(date[1]).endOf('day'),
        undefined, '[]',
      ));
      setFilteredOrders([...filter]);
    } else {
      const filter = sales.filter(item => moment(item[`${search}`]).isBetween(
        moment(date[0]).startOf('days'),
        moment(date[0]).endOf('day'),
        undefined, '[]',
      ));
      setFilteredOrders([...filter]);
    }
  };


  const makeSearch = (searchValue) => {
    const sales = [...deliverList];
    const filter = sales.filter(item => item[`${search}`].toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredOrders([...filter]);
  };

  const getPayColor = (payStatus) => {
    let payColor = '';
    switch (payStatus) {
      case 'Contra Entrega':
        payColor = '#f05b70';
        break;
      case 'Credito':
      case 'Pagado':
        payColor = '#23a1db';
        break;

      default:
        payColor = '#535d6f';
        break;
    }

    return payColor;
  };

  React.useEffect(() => {
    if (store) {
      getPending();
    }
  }, [store]);

  return (
    <>
      {!loading && (
      <div>
        <div
          className="d-flex w-100 px-2 py-2"
          style={{ backgroundColor: '#fff', borderRadius: 20, margin: '6px 0' }}
        >
          <select
            style={{
              border: 0,
              borderRadius: 10,
              padding: '0 5px',
              marginRight: '0.5rem',
              outline: 'none',
              backgroundColor: '#ebebeb',
            }}
            onChange={e => setSearch(e.target.value)}
          >
            <option selected={search === 'estado'} value="estado">
              Estado
            </option>
            <option selected={search === 'treebesalmid_label'} value="treebesalmid_label">
              Almacen
            </option>
            <option selected={search === 'idventa'} value="idventa">
              Num. venta
            </option>
            <option selected={search === 'id_envio'} value="id_envio">
              Num. envio
            </option>
            <option selected={search === 'fecha_hora_solicitud'} value="fecha_hora_solicitud">
              Fecha venta
            </option>
          </select>
          {search === 'fecha_hora_solicitud' ? (
            <CustomDatePicker
              style={{ height: 30 }}
              onChange={handleChangeDate}
              headerName="date"
            />
          ) : (
            <input
              autoFocus
              style={{
                border: 0,
                width: '100%',
                backgroundColor: 'transparent',
                outline: 'none',
              }}
              onChange={e => makeSearch(e.target.value)}
              type="text"
            />
          )}
          <IconButton style={{ height: 30, color: '#313c52' }}>
            <Search id="actions_search_tag" />
          </IconButton>
        </div>
        {filteredOrders.length ? (
          <div style={{ paddingBottom: 66 }}>
            {filteredOrders.map(sale => (
              <ListItemGeneric
                key={`${sale.crmid}_key_${Math.random()}`}
                titleLeft={(
                  <>
                    <div>{sale.asunto_envio}</div>
                    <div>
                      <small>{sale.relatedtoid_label}</small>
                      <span> | </span>
                      <small>{sale.dest_alias}</small>
                    </div>
                  </>
                )}
                expandData={<ShipmentExpand sale={sale} />}
                subTitleLeft={(
                  <>
                    <p style={{ color: '#313c52' }}>
                      {moment(sale.fecha_hora_solicitud).format(
                        'DD MMM, h:mm a',
                      )}
                      <span>
                        {' '}
                        |
                        {' '}
                      </span>
                      <span style={{
                        backgroundColor: getPayColor(sale.tipo_pago),
                        color: 'white',
                        padding: '2px 3px',
                        borderRadius: '5px',
                      }}
                      >
                        {sale.tipo_pago}
                      </span>
                    </p>
                    <span style={{ color: '#313c52' }}>{getDirection('destino', sale)}</span>
                    <br />
                    <span style={{ color: '#313c52' }}>
                      {sale.treebesalmid_label}
                      {' '}
                      |
                      {' '}
                      {sale.vehiculo_label}
                      {' '}
                      |
                      {' '}
                      <span style={{
                        backgroundColor: getColorDeliverStatus(`${sale.tse_estado_i}`), color: 'white', padding: '2px 4px', borderRadius: '4px',
                      }}
                      >
                        {`${sale.tse_estado_i}`.split('-').pop()}
                      </span>
                    </span>
                  </>
                )}
                titleRight={(
                  <IconWidthBG
                    primaryColor={getColorDeliverStatus(`${sale.tse_estado_i}`)}
                    secondaryColor={`${
                      sale.estado_envio === 'Created'
                        || sale.estado_envio === 'Creado'
                        ? '#B6BEC6'
                        : 'white'
                    }`}
                    width={27}
                    height={27}
                    classes="ml-2"
                    enabled
                    square
                    rounded
                    actionClick={() => {}}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: 15 }}
                      icon={faTruck}
                    />
                  </IconWidthBG>
                )}
                /* subTitleRight={(
                  <span style={{ color: '#313c52' }}>
                    {sale.estado_envio}
                  </span>
                    )} */
              />
            ))}
          </div>
        ) : (
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText
                primary="No se encontraron ventas"
              />
            </ListItem>
          </List>
        )}
      </div>
      )}

      {loading && (
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      )}
    </>
  );
};

export default Shipments;
