/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import './DetailFooter.scss';
import {
  Collapse,
  LinearProgress,
  ButtonBase,
} from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';

import DetailFooterLong from './DetailFooterLong';
import DetailFooterShort from './DetailFooterShort';

import CheckOutModal from '../../../../../../CheckOut/_components/CheckOutModal';

const DetailFooter = ({
  cart,
  timbrar,
  history,
  customer,
  loading,
  sostatus,
  discount,
  showDeliver,
  pos_checkout_bill,
  orderStatuses,
  paymentTypes,
  makeSell,
  editOrder,
  deliverMoney,
  handleMakeBill,
  changeSalesOrderStatus,
}) => {
  /* Footer */
  const [open, setOpen] = React.useState(false);

  /* Checkout */
  const [check, setCheck] = React.useState(false);
  const toggleCheck = () => setCheck(!check);
  const { makeBill } = timbrar;
  const { orderSelected } = cart;
  const orderMode = Boolean(orderSelected ? Object.keys(orderSelected).length > 0 : 0);
  const withDiscount = (Number.parseFloat(cart.total) - (discount.total).toFixed(2));
  const generalBalance = (withDiscount - Number.parseFloat(cart.pagado));
  return (
    <div className="detailFooter">
      {loading && (
      <LinearProgress className="w-100" />
      )}
      <Collapse
        in={!loading}
        className="w-100"
        timeout={{
          appear: 0,
          enter: 250,
          exit: 100,
        }}
      >
        <ButtonBase
          className="w-100 p-1 "
          style={{ backgroundColor: '#F6F6F7', outline: 'none' }}
          disabled={!loading && cart.totalProducts === 0}
          onClick={() => setOpen(!open)}
        >
          <KeyboardArrowUp
            className={open ? 'mda_drop' : 'mda_drop noDropDown'}
          />
        </ButtonBase>
      </Collapse>
      <Collapse
        in={open}
        className="w-100"
        timeout={{
          appear: 100,
          enter: 250,
          exit: 300,
        }}
      >
        <DetailFooterLong cart={cart} discount={discount} />
      </Collapse>
      <Collapse
        in={!loading}
        className="w-100"
        timeout={{
          appear: 0,
          enter: 250,
          exit: 100,
        }}
      >
        <DetailFooterShort
          open={open}
          cart={cart}
          discount={discount}
          orderMode={orderMode}
          toggleCheck={toggleCheck}
          showDeliver={showDeliver}
        />
      </Collapse>
      <CheckOutModal
        isOpen={check}
        toggle={toggleCheck}
        cart={cart}
        loading={loading}
        makeBill={makeBill}
        customer={customer}
        defaultOrderStatus={sostatus}
        dispatchEditOrder={editOrder}
        handleMakeBill={handleMakeBill}
        orderStatuses={orderStatuses}
        paymentTypes={paymentTypes}
        posCheckoutBill={pos_checkout_bill}
        totalToPay={generalBalance}
        title={() => {
          if (orderMode) {
            const { order } = cart.orderSelected;
            return `Cobrar Orden: ${order.customerno}`;
          }
          return null;
        }}
        onSave={(checkOut) => {
          toggleCheck();
          if (orderMode) {
            const { payments } = checkOut;
            const orderId = cart.orderSelected.crmid;
            deliverMoney(payments, orderId);
          } else {
            const { currentOrderStatus, payments } = checkOut;
            changeSalesOrderStatus(currentOrderStatus);
            makeSell(payments, history);
          }
        }}
      />
    </div>
  );
};

DetailFooter.propTypes = {
  cart: PropTypes.object,
  customer: PropTypes.object,
  timbrar: PropTypes.object,
  discount: PropTypes.object,
  sostatus: PropTypes.string,
  pos_checkout_bill: PropTypes.string,
  orderStatuses: PropTypes.array,
  paymentTypes: PropTypes.array,
  loading: PropTypes.bool,
  showDeliver: PropTypes.bool,
  history: PropTypes.object,
  makeSell: PropTypes.func,
  editOrder: PropTypes.func,
  deliverMoney: PropTypes.func,
  handleMakeBill: PropTypes.func,
  changeSalesOrderStatus: PropTypes.func,
};

DetailFooter.defaultProps = {
  cart: {},
  customer: {},
  timbrar: {},
  discount: {},
  sostatus: '',
  pos_checkout_bill: '',
  orderStatuses: [],
  paymentTypes: [],
  loading: false,
  showDeliver: true,
  history: {},
  makeSell: () => {},
  editOrder: () => {},
  deliverMoney: () => {},
  handleMakeBill: () => {},
  changeSalesOrderStatus: () => {},
};

export default DetailFooter;
